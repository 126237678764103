import { NodeData } from 'react-folder-tree';
export const dashboardTree: NodeData = {
  name: 'cPacket Dashboards',
  dashPath: 'assets/dashboards',
  checked: 0.5,
  isOpen: true,
  children: [
    {
      name: 'Devices',
      dashPath: 'devices',
      checked: 1,
      isOpen: true,
      children: [
        { name: 'cVuNG cBurst Metrics', dashPath: 'cvung_cburst_metrics.json', checked: 1 },
        { name: 'cVuNG Metrics', dashPath: 'cvung_metrics.json', checked: 1 },
        { name: 'cVuAG/EAG Metrics', dashPath: 'cvuag_eag_metrics.json', checked: 1 },
        { name: 'cStor Counters', dashPath: 'cstor_counters.json', checked: 1  },
        { name: 'cVuAG/EAG cBurst Filters', dashPath: 'cvuag_eag_cburst_filters.json', checked: 0 },
        { name: 'cVuAG/EAG Port Counters', dashPath: 'cvuag_eag_port_counters.json', checked: 0 },
        { name: 'cVuNG Port Feature Finder', dashPath: 'cvung_port_feature_finder.json', checked: 0 },
        // { name: 'cVuAG/EAG - TODO', dashPath: 'cvuag_eag___todo.json', checked: 0 },
        // { name: 'cVuNG - TODO', dashPath: 'cvung___todo.json', checked: 0 },
      ],
    },
    {
      name: 'Troubleshooting',
      dashPath: 'troubleshooting',
      checked: 1,
      isOpen: true,
      children: [
        { name: 'Host Troubleshooting', dashPath: 'host_troubleshooting.json', checked: 1 },
        { name: 'IP Summary (Flows)', dashPath: 'ip_summary_(flows).json', checked: 1 },
        { name: 'IP Summary (TCP)', dashPath: 'ip_summary_(tcp).json', checked: 1 },
        { name: 'IP Conversations (Flows)', dashPath: 'ip_conversations_(flows).json', checked: 1 },
        { name: 'IP Conversations (Flows 5 Tuple)', dashPath: 'ip_conversations_(flows_5_tuple).json', checked: 0 },
        { name: 'IP Conversations (TCP)', dashPath: 'ip_conversations_(tcp).json', checked: 1 },
        { name: 'IP Conversations (TCP 5 Tuple)', dashPath: 'ip_conversations_(tcp_5_tuple).json', checked: 0 },
        { name: 'Host Finder', dashPath: 'host_finder.json', checked: 1 },
      ],
    },
    {
      name: 'Analytics',
      dashPath: 'analytics',
      checked: 1,
      isOpen: true,
      children: [
        { name: 'Applications Overview', dashPath: 'applications_overview.json', checked: 1 },
        { name: 'Hosts Groups Overview', dashPath: 'hosts_groups_overview.json', checked: 1 },
        { name: 'VLANs Overview', dashPath: 'vlans_overview.json', checked: 1 },
        { name: 'Application Hosts', dashPath: 'application_hosts.json', checked: 1 },
        { name: 'Hosts Group Hosts', dashPath: 'hosts_group_hosts.json', checked: 1 },
      ],
    },
    {
      name: 'Flows Overview',
      dashPath: 'flow_analytics',
      checked: 1,
      isOpen: true,
      children: [
        { name: 'Flows Overview', dashPath: 'flows_overview.json', checked: 1 },
        { name: 'Network Applications Overview (Flows)', dashPath: 'network_application_overview_(flows).json', checked: 1 },
        { name: 'IPs for a Network Application (Flows)', dashPath: 'ips_for_a_network_application_(flows).json', checked: 1 },
        { name: 'VLANs Overview (Flows)', dashPath: 'vlan_overview_(flows).json', checked: 1 },
        { name: 'IPs for a VLAN (Flows)', dashPath: 'ips_for_a_vlan_(flows).json', checked: 1 },
        { name: 'Applications Overview (Flows)', dashPath: 'application_overview_(flows).json', checked: 1 },
        { name: 'Application Conversations (Flows)', dashPath: 'application_conversations_(flows).json', checked: 1 },
        { name: 'IPs for an Application (Flows)', dashPath: 'ips_for_an_application_(flows).json', checked: 1 },
      ],
    },
    {
      name: 'TCP Overview',
      dashPath: 'tcp_analytics',
      checked: 1,
      isOpen: true,
      children: [
        { name: 'TCP Overview', dashPath: 'tcp_overview.json', checked: 1 },
        { name: 'Network Applications Overview (TCP)', dashPath: 'network_application_overview_(tcp).json', checked: 1 },
        { name: 'IPs for a Network Application (TCP)', dashPath: 'ips_for_a_network_application_(tcp).json', checked: 1 },
        { name: 'VLANs Overview (TCP)', dashPath: 'vlan_overview_(tcp).json', checked: 1 },
        { name: 'IPs for a VLAN (TCP)', dashPath: 'ips_for_a_vlan_(tcp).json', checked: 1 },
        { name: 'Applications Overview (TCP)', dashPath: 'application_overview_(tcp).json', checked: 1 },
        { name: 'Application Conversations (TCP)', dashPath: 'application_conversations_(tcp).json', checked: 1 },
        { name: 'IPs for an Application', dashPath: 'ips_for_an_application_(tcp).json', checked: 1 },
        { name: 'Applications Overview (TCP Summary IP)', dashPath: 'application_overview_(tcp_summary_ip).json', checked: 1 }
      ],
    },
    {
      name: 'Application Analytics',
      dashPath: 'application_analytics',
      checked: 0,
      isOpen: true,
      children: [
        { name: 'HTTPS Analytics', dashPath: 'https_analytics.json' },
        { name: 'DHCP Analytics', dashPath: 'dhcp_analytics.json' },
        { name: 'DNS Analytics', dashPath: 'dns_analytics.json' },
        { name: 'ICMP Analytics', dashPath: 'icmp_analytics.json' },
        { name: 'cMDF Yeti + Sapphire (Market Data)', dashPath: 'cmdf_yeti_+_sapphire_(market_data).json' },
        { name: 'Multicast & cMDF', dashPath: 'multicast_&_cmdf.json' },
        { name: 'Multicast Analytics', dashPath: 'multicast_analytics.json' },
        { name: 'Video Multicast', dashPath: 'video_multicast.json' },
        { name: 'VPN (ESP)', dashPath: 'vpn_(esp).json' },
        { name: 'Who is accessing AVSVMCLOUD?', dashPath: 'who_is_accessing_avsvmcloud.json' },
        { name: 'IP Map - Canonical Names and CDNs', dashPath: 'ip_map___canonical_names_and_cdns.json' },
        { name: 'IP Map - HTTPS DPI', dashPath: 'ip_map___https_dpi.json' },
        { name: 'IP Map - User Defined Applications', dashPath: 'ip_map___user_defined_applications.json' },
        { name: 'Overview: Application Usage', dashPath: 'overview_application_usage.json' },
        { name: 'Overview: External NAT Dependency', dashPath: 'overview_external_nat_dependency.json' },
        { name: 'Explore TCP Unknown Application Ports', dashPath: 'explore_tcp_unknown_application_ports.json' },
        { name: 'Internet Applications Flows Details', dashPath: 'internet_applications_flows_details.json' },
        { name: 'Internet Applications TCP Sessions Details', dashPath: 'internet_applications_tcp_sessions_details.json' },
        { name: 'Scanners View', dashPath: 'scanners_view.json' },
        // { name: 'TCP IP Summary Copy', dashPath: 'tcp_ip_summary_copy.json' },
        // { name: 'Testing Summary', dashPath: 'testing_summary.json' },
      ],
    },
    {
      name: 'Custom Dashboards',
      dashPath: 'custom',
      checked: 1,
      isOpen: false,
      children: [
        { name: 'VLANs for an IP (Flows)', dashPath: 'vlans_for_an_ip_(flows).json', checked: 1 },
        { name: 'VLANs for an IP (TCP)', dashPath: 'vlans_for_an_ip_(tcp).json', checked: 1 },
      ],
    },
    {
      name: 'System',
      dashPath: 'system',
      checked: 1,
      isOpen: false,
      children: [
        { name: 'InfluxDB Internals', dashPath: 'influxdb_internals.json', checked: 1 },
        { name: 'cPacket Database Schema', dashPath: 'cpacket_database_schema.json', checked: 1 },
        { name: 'cPacket Database Series Cardinality', dashPath: 'cpacket_database_series_cardinality.json', checked: 1 },
        { name: 'Home', dashPath: 'home.json', checked: 1 },
      ],
    },
    {
      name: 'Debug',
      dashPath: 'debug',
      checked: 0,
      isOpen: false,
      children: [
        { name: 'cPacket Debug Data', dashPath: 'cpacket_debug_data.json', checked: 0 },
      ],
    },
  ],
};
