import { PluginConfigPageProps, AppPluginMeta } from '@grafana/data';
import React, {useState} from 'react';
import 'react-folder-tree/dist/style.css';
import { Styles } from 'types';
import { WithStyles } from './styles';
import { dashboardTree } from './tree/TreeStates';
import { HorizontalGroup, VerticalGroup } from '@grafana/ui';
import { DashTree } from './tree';
import { Import } from './import';

interface Props extends PluginConfigPageProps<AppPluginMeta> {
  styles: Styles;
}

const BasePanel: React.FC<Props> = ({plugin,styles})=> {
  const [treeState, setTreeState] = useState(dashboardTree);

    return (
    <div>
      <VerticalGroup>
        <HorizontalGroup>
          <div className = {styles.pagePadding} >
            <Import treeState={treeState} pluginBaseURL={plugin.meta.baseUrl} />
          </div>
        </HorizontalGroup>
        <HorizontalGroup>
          <DashTree treeState={treeState} setTreeState={setTreeState} />
        </HorizontalGroup>
      </VerticalGroup>
    </div>
    );
}

export const ImportPage = WithStyles(BasePanel);

