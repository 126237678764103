import { AppPlugin } from '@grafana/data';
import { ImportPage } from 'importpage';
import { PluginPage } from 'plugins/PluginPage';
import { RootPage } from 'rootpage/RootPage';

export const plugin = new AppPlugin().setRootPage(RootPage)
.addConfigPage({
  title: 'Dashboards',
  icon: 'apps',
  body: ImportPage,
  id: 'import',
})
.addConfigPage({
  title: 'cClear Plugins',
  icon: 'create-dashboard',
  body: PluginPage,
  id: 'plugins',
});
