import { PluginConfigPageProps, AppPluginMeta } from '@grafana/data';
import React, { useEffect, useState } from 'react';
import { Styles, cPacketPlugin } from 'types';
import { Button, ConfirmModal, FileUpload, HorizontalGroup, Spinner, VerticalGroup } from '@grafana/ui';
import { fetchPlugins, installPlugin, uninstallPlugin } from './service';
import { cx } from '@emotion/css';
import JSZip from 'jszip';
import { WithStyles } from './styles/WithStyles';

interface Props extends PluginConfigPageProps<AppPluginMeta> {
  styles: Styles;
}

const BasePanel: React.FC<Props> = ({ plugin, styles }) => {
  const [plugins, setPlugins] = useState<cPacketPlugin[]>([]);
  const [message, setMessage] = useState<string>('');
  const [installRequested, setInstallRequested] = useState<boolean>(false);
  const [uninstallRequested, setUninstallRequested] = useState<boolean>(false);
  const [zipFile, setZipFile] = useState<File>();
  const [selectedPlugin, setSelectedPlugin] = useState<cPacketPlugin | undefined>(undefined);
  const [updatePugin, setUpdatePlugin] = useState<boolean>(false);
  const [promptInstall, setPromptInstall] = useState<string>('');
  const [promptTitle, setPromptTitle] = useState<string>('');
  const [inProgress, setInProgress] = useState<boolean>(false);

  // update status message and set busy indicator
  const updateStatus = (message: string, busy: boolean) => {
    setMessage(message);
    setInProgress(busy);
  }

  // find file with provided name, return the one closest to root
  const findFile = (files: { [key: string]: JSZip.JSZipObject }, fileName: string) => {
    let foundFile: JSZip.JSZipObject | undefined = undefined;
    for (const fileKey of Object.keys(files)) {
      if (fileKey.endsWith(fileName)) {
        if (!foundFile || !foundFile.name || foundFile.name.length > files[fileKey].name.length) {
          foundFile = files[fileKey];
        }
      }
    }
    return foundFile;
  }

  // update selected plugin and request state
  const prepUninstall = (pluginID: string) => {
    if (!plugins) {
      updateStatus('No plugins available to uninstall.', false);
      return;
    }
    const selected = plugins.find(plugin => plugin.id === pluginID);
    setSelectedPlugin(selected);
    setUninstallRequested(true);
  };

  // uninstall selected plugin and reload plugins on success
  const uninstall = () => {
    if (!selectedPlugin) {
      updateStatus('No plugin selected to uninstall.', false);
      return;
    }
    // uninstall plugin
    updateStatus(`Uninstalling ${selectedPlugin.name} version ${selectedPlugin.version}...`, true);
    uninstallPlugin(selectedPlugin.id).then(async response => {
      if (!response.ok) {
        console.error(response);
        updateStatus(`Failed to uninstall ${selectedPlugin.name} ${selectedPlugin.version}: ${response.status}, ${response.statusText}.`, false);
      } else {
        // reload plugins
        loadPlugins();
      }
    })
  };

  // select plugin to install and validate plugin file
  const prepInstall = async (event: React.FormEvent<HTMLInputElement>) => {
    const target = event.target as HTMLInputElement;
    if (!target.files || target.files.length === 0) {
      return;
    }
    // limit file size
    const zipFile = target.files[0];
    const OneMB = (1024 * 1024);
    const maxSize = 500 * OneMB;
    const fileSize = zipFile.size / OneMB;
    if (zipFile.size > maxSize) {
      updateStatus(`File (${zipFile.name}) size exceeds the limit of 500MB: ${Math.round(fileSize)} MB`, false);
      return;
    }
    // plugin.json
    const zip = new JSZip();
    const data = await zip.loadAsync(zipFile, { createFolders: true });
    let newPlugin: cPacketPlugin;
    const pluginFile = findFile(data.files, "plugin.json");
    if (!pluginFile) {
      updateStatus(`Invalid plugin file: "plugin.json" does not exist in archive ${zipFile.name}.`, false);
      return;
    } else {
      const textPlugin = await pluginFile.async('text');
      const jsonPlugin = JSON.parse(textPlugin);
      newPlugin = { id: jsonPlugin.id, name: jsonPlugin.name, version: jsonPlugin.info.version, updated: jsonPlugin.info.updated, author: jsonPlugin.info.author };
      setSelectedPlugin(newPlugin);
    }
    // MANIFEST.TXT
    const signatureFile = findFile(data.files, "MANIFEST.txt");
    if (!signatureFile) {
      updateStatus(`Invalid plugin file: "MANIFEST.txt" does not exist in archive ${zipFile.name}. Unsigned plugins are not supported.`, false);
      return;
    }
    // update file to install
    setZipFile(zipFile);
    setInstallRequested(true);
    // update prompt messages for modal diaglogs: new vs update
    setPromptTitle(`Install Plugin: ${newPlugin.name}`);
    if (!plugins || plugins.length === 0) {
      setPromptInstall(`This will install ${newPlugin.name} version ${newPlugin.version} from file ${zipFile.name}.\nDo you wish to continue?`);
    } else {
      const foundPlugin = plugins.find(plugin => plugin.id === newPlugin.id);
      if (foundPlugin) {
        setUpdatePlugin(true);
        setPromptTitle(`Update Plugin: ${newPlugin.name}`);
        setPromptInstall(`This will update ${newPlugin.name} from version ${foundPlugin.version} to ${newPlugin.version} with file ${zipFile.name}. Do you wish to continue?`);
      } else {
        setUpdatePlugin(false);
        setPromptInstall(`This will install ${newPlugin.name} version ${newPlugin.version} from file ${zipFile.name}. \nDo you wish to continue?`);
      }
    }
  }

  // install selected plugin and reload plugins on success
  const install = async () => {
    if (!zipFile) {
      updateStatus('No plugin file selected.', false);
      return;
    }
    // install plugin
    updatePugin ? updateStatus(`Updating ${selectedPlugin?.name} to version ${selectedPlugin?.version} ...`, true) : updateStatus(`Installing ${selectedPlugin?.name} version ${selectedPlugin?.version} ...`, true);
    installPlugin(zipFile, updatePugin).then(async response => {
      if (response.ok) {
        updateStatus('Plugin installed successfully.', false);
        // reload plugins
        loadPlugins();
      } else {
        updateStatus(`Failed to ${updatePugin ? "update" : "install"} ${selectedPlugin?.name} version ${selectedPlugin?.version}: ${response.status}, ${response.statusText}.`, false);
      }
    })
  }

  // load plugins from server
  const loadPlugins = async () => {
    updateStatus('Loading plugins...', true);
    return fetchPlugins().then(async response => {
      if (response.ok) {
        setPlugins(response.plugins);
        updateStatus('', false);
      } else {
        console.error(response);
        updateStatus(`Failed to load plugins: ${response.status}, ${response.statusText}`, false);
      }
    })
  }

  useEffect(() => {
    loadPlugins();
    // disable eslin for next line: would cause infinite loop if adding loadPlugins to dependency array
  }, []);  // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className={styles.pagePadding}>
      <HorizontalGroup width='100%' align='center' justify='center' spacing='lg'>
        <h2>cClear Plugin Management</h2>
      </HorizontalGroup>
      {plugins && plugins.length > 0 && <div className={styles.managementLayout}>
        <HorizontalGroup width='100%' align='center' justify='center' spacing='lg'>
          <VerticalGroup justify='center' className={styles.rightAlignment}>
            {plugins && plugins.map((cpktPlugin: cPacketPlugin) => (
              <HorizontalGroup key={cpktPlugin.id} justify='flex-end' spacing='lg' >
                <label>{cpktPlugin.name}</label>
                <label>{cpktPlugin.version}</label>
                {cpktPlugin.id === plugin.meta.id &&
                  <Button tooltip={`Cannot uninstall this hosting plugin. To update it to a different version: click "Install or Update" button to the right.`} size='xs' variant='destructive' disabled={true} onClick={() => prepUninstall(cpktPlugin.id)}>Uninstall</Button>
                }
                {cpktPlugin.id !== plugin.meta.id &&
                  <Button tooltip={`Uninstall ${cpktPlugin.name}`} tooltipPlacement="right-start" size='xs' variant='destructive' onClick={() => prepUninstall(cpktPlugin.id)}>Uninstall</Button>
                }
                <ConfirmModal
                  isOpen={uninstallRequested}
                  title={`Uninstall Plugin: ${selectedPlugin?.name} version ${selectedPlugin?.version}  `}
                  body={`This will uninstall ${selectedPlugin?.name} version ${selectedPlugin?.version}. Do you wish to continue?`}
                  icon={'question-circle'}
                  confirmText={'Yes'}
                  onConfirm={() => {
                    setUninstallRequested(false);
                    uninstall();
                  }}
                  onDismiss={() => {
                    setUninstallRequested(false);
                  }}
                ></ConfirmModal>
              </HorizontalGroup>
            ))}
          </VerticalGroup>
          <VerticalGroup>
            <FileUpload showFileName={false} accept='.zip' size='lg' onFileUpload={(event: React.FormEvent<HTMLInputElement>) => prepInstall(event)}>Install/Update</FileUpload>
            <ConfirmModal
              isOpen={installRequested}
              title={promptTitle || `Install Plugin: ${selectedPlugin?.name}`}
              body={promptInstall}
              icon={'question-circle'}
              confirmText={'Yes'}
              confirmVariant='success'
              onConfirm={() => {
                setInstallRequested(false);
                install();
              }}
              onDismiss={() => {
                setInstallRequested(false);
              }}
            ></ConfirmModal>
          </VerticalGroup>
        </HorizontalGroup>
      </div >
      }
      <HorizontalGroup padding-top='2em' justify='center' align='center' spacing='lg' width='100%'>
        {!inProgress && <div className={cx(styles.statusStyle, styles.errorText)}><span>{message}</span></div>}
        {inProgress &&
          <div className={cx(styles.statusStyle)}><span>{message}</span></div>
        }
        {inProgress && <Spinner />}
      </HorizontalGroup>
    </div >
  );
}

export const PluginPage = WithStyles(BasePanel);
