import { Icon } from '@grafana/ui';
import React from 'react';
import FolderTree, { IconProps, NodeData } from 'react-folder-tree';
import 'react-folder-tree/dist/style.css';
import { dashboardTree } from './TreeStates';

interface Props {
  treeState: NodeData;
  setTreeState: Function;
}

export const DashTree: React.FC<Props> = ({ treeState, setTreeState }) => {
  const onTreeStateChange = (state: React.SetStateAction<NodeData>, event: any) => {
    if (JSON.stringify(treeState) === JSON.stringify(state)) {
      return;
    }
    setTreeState(state);
  };
  const onNameClick = (opts: { defaultOnClick: () => void; nodeData: NodeData }) => {
    // todo: open the clicked dashboard in a new browser tab
    const e = { type: 'checkNode', path: opts.nodeData.path, params: [1] };
    onTreeStateChange(treeState, e);
    opts.nodeData.checked = opts.nodeData.checked ? 0 : 1;
    setTreeState((prev: NodeData) => {
      return { ...prev, checked: 1 };
    });
  };

  const dashIcon: React.FunctionComponent<IconProps> = ({ nodeData: nodeData, onClick: click }: IconProps) => {
    const handleClick = () => {
      // do nothing for now
    };

    return <Icon name="apps" onClick={handleClick} />;
  };

  return (
    <FolderTree
      data={dashboardTree}
      initCheckedStatus={'custom'}
      initOpenStatus={'custom'}
      onChange={onTreeStateChange}
      onNameClick={onNameClick}
      showCheckbox={true}
      indentPixels={40}
      iconComponents={{
        FileIcon: dashIcon,
      }}
    />
  );
};
