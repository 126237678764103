import React from 'react';
import { css } from '@emotion/css';
import { GrafanaTheme2, AppRootProps } from '@grafana/data';
import { LinkButton, useStyles2 } from '@grafana/ui';
import { PluginPage } from '@grafana/runtime';
import { InfoSection } from 'ondemand/views/Panel/InfoPanel/InfoSection';
// const ReactMarkdown = require('react-markdown')

export function RootPage(props: AppRootProps) {
  const s = useStyles2(getStyles);
  const url_import = '/plugins/'+ props.meta.id + '?page=import';
  return (
    <PluginPage>
      <div>
        <InfoSection title="">
          <h3>Built to work with our cClear product for Network Observability</h3>
          <hr/>
          <p>Version 4.4.1</p>
          <p>Provides deep Grafana integration with cClear:</p>
            <ul style={{ marginLeft: '2rem' }}>
              <li>
              A collection of dashboards for network observability
              </li>
              <li>
              A packet analytics collector panel
              </li>
              <li>
              A packet download panel
              </li>
            </ul>
            <p/>
        </InfoSection>
        <div className={s.marginTop}>
          <p>To import dashboards, go to:</p>
          <LinkButton href={url_import}>
            Dashboards
          </LinkButton>
        </div>
      </div>
    </PluginPage>
  );
}

const getStyles = (theme: GrafanaTheme2) => ({
  marginTop: css`
    margin-top: ${theme.spacing(2)};
  `,
});
