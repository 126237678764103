import { cPacketPlugin } from "types";

// consistent response for clients to work with
export type PluginsFetchResponse = {
    ok: boolean;
    status: string | number;
    statusText: any;
    plugins: cPacketPlugin[];
}

// Install or update a plugin with provided file
export async function installPlugin(file: File, update = false) {
    const formData = new FormData();
    formData.append('plugin', file);
    try {
        const response = await fetch('/api/v2/admin/dashboards/plugins', {
            method: update ? 'PUT' : 'POST',
            body: formData
        });
        if (response.ok) {
            const pluginResponse = await response.json();
            return { ok: true, status: pluginResponse.status, statusText: pluginResponse.message, plugins: [] };
        } else {
            console.error(response);
            try {
                // from plugin fetch response
                const result = await response.json();
                return { ok: false, status: result.status, statusText: !!result.statusText ? result.statusText : result.toString(), plugins: [] };
            } catch (error: any) {
                // all others
                return { ok: false, status: response.status, statusText: !!response.statusText ? response.statusText : response, plugins: [] };
            }
        }
    } catch (error: any) {
        return { ok: false, status: 500, statusText: `Failed to POST/PUT: ${error.toString()}`, plugins: [] };
    }
}

// uninstall a plugin with provided ID
export async function uninstallPlugin(pluginID: string) {
    try {
        const response = await fetch(`/api/v2/admin/dashboards/plugins/${pluginID}`, {
            method: 'DELETE'
        });
        if (response.ok) {
            const pluginResponse = await response.json();
            return { ok: true, status: 200, statusText: !!pluginResponse.message, plugins: [] };
        } else {
            console.error(response);
            try {
                // from plugin fetch response
                const result = await response.json();
                return { ok: false, status: result.status, statusText: !!result.statusText ? result.statusText : result.toString(), plugins: [] };
            } catch (error: any) {
                // all others
                return { ok: false, status: response.status, statusText: !!response.statusText ? response.statusText : response.toString(), plugins: [] };
            }
        }
    } catch (error: any) {
        return { ok: false, status: 500, statusText: `Failed to DELETE: ${error.toString()}`, plugins: [] };
    }
}

// fetch all plugins
export async function fetchPlugins() {
    try {
        const response = await fetch('/api/v2/admin/dashboards/plugins');
        if (response.ok) {
            const data = await response.json();
            if (!data || !data.data || !data.data.plugins) {
                return { ok: false, status: data.status, statusText: !!data.message ? data.message : response.statusText, plugins: [] };
            } else {
                return { ok: true, status: response.status, statusText: response.statusText, plugins: data.data.plugins };
            }
        } else {
            console.error(response);
            try {
                const data = await response.json();
                if (!!data) {
                    // from plugin fetch response
                    return { ok: false, status: `${response.status} ${data.status}`, statusText: !!data.message ? data.message : response.statusText, plugins: [] };
                } else {
                    // other non json responses
                    return { ok: false, status: response.status, statusText: !!response.statusText ? response.statusText : response.toString(), plugins: [] };
                }
            } catch (error: any) {
                // all others 
                return { ok: false, status: response.status, statusText: !!response.statusText ? response.statusText : response.toString(), plugins: [] };
            }
        }
    } catch (error: any) {
        return { ok: false, status: 500, statusText: `Failed to POST/PUT: ${error.toString()}`, plugins: [] };
    }
}
