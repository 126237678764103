import { config } from '@grafana/runtime';


// return <Folder> | undefined
export async function postFolder(folder: { [key: string]: any }) {
  const response = await fetch(`${config.appSubUrl}/api/folders`, {
    method: 'POST',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(folder),
  });
  if (!response.ok) { return undefined; }
  return response.json();
}

// return dashboard json or undefined
export async function fetchDashboard(file: string) {
  const response = await fetch(file);
  if (!response.ok) { return undefined; }
  return response.json()
}

// return Arrary<Folder> or []
export async function fetchFolders() {
  const response = await fetch(`${config.appSubUrl}/api/folders`);
  if (!response.ok) { return []; }
  const data = await response.json();
  if (!data) {
    return [];
  } else {
    return data;
  }
}
