import React from 'react';
import { css } from '@emotion/css';
import { useStyles2, useTheme } from '@grafana/ui';

type Props = {
  [key: string]: any;
};

export const WithStyles = (WrappedComponent: React.FC<any>): React.FC<Props> => {
  const HOC: React.FC = (props: Props): JSX.Element => {
    const theme = useTheme();
    const styles = useStyles2(() => {
      return {
        pagePadding: css`
          padding-top: 5em;
          padding-left: 10rem;
          margin-bottom: 5rem;
        `,
        managementLayout: css`
        height: 100%;
        padding-top: 2rem;
        padding-bottom: 1rem;
        padding-right: 2rem;
        display: flex;
        flex-direction: column;
        align-items: center;
        `,
        statusStyle: css`
        margin-top: 1rem;
        text-align: center;
        text-justify: center;
        font-style: italic;
        margin-bottom: 1rem;
      `,
        rightAlignment: css`
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-right: 2rem;
        `,
        errorText: css`
          color: ${theme.palette.red88};
        `,
      };
    });

    return <WrappedComponent {...props} styles={styles} />;
  };
  return HOC;
};
