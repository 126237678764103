import { Alert, AlertVariant, Button, VerticalGroup } from '@grafana/ui';
import React, { useState, useEffect } from 'react';
import { NodeData } from 'react-folder-tree';
import { fetchDashboard, fetchFolders, postFolder } from 'importpage/service';
import { config } from '@grafana/runtime';

interface Folder {
  id: number;
  uid: string;
  title: string;
}

interface Props {
  pluginBaseURL: string;
  treeState: NodeData;
}

export const Import: React.FC<Props> = ({ pluginBaseURL, treeState }) => {
  const [showAlert, setShowAlert] = useState<boolean>(false);
  const [status, setStatus] = useState<AlertVariant>('error');
  const [message, setMessage] = useState<string>('');
  const HOME_DASHBOARD = "cpacket_system_home";
  const importDashboards = () => {
    // import
    fetchFolders().then(async response => {
      let folders: Folder[] = response;
      // do nothing if no childrent or nothing selected
      if (!treeState.children || treeState.children?.every((child: NodeData) => child.checked === 0)) {
        return;
      }
      for (const child of treeState.children) {
        if (!child.checked || !child.children) {
          continue;
        }
        for (const gchild of child.children) {
          if (!gchild.checked) {
            continue;
          }
          await importDashboard(`${pluginBaseURL}/${treeState.dashPath}/${child.dashPath}/${gchild.dashPath}`, folders);
        }
      }
      // If home is never set, import home and set it
      const userHome = await getUserHomePage();
      const orgHome = await getOrgHomePage();
      if (!userHome || !orgHome) {
        await importDashboard(`${pluginBaseURL}/${treeState.dashPath}/system/home.json`, folders);
        if (!userHome) {
          setUserHomePage();
        }
        if (!orgHome) {
          setOrgHomePage();
        }
      } else {
        if (treeState.checked){
          const system: NodeData | undefined = treeState.children?.find((child: NodeData) => child.dashPath === "system");
          if (system?.checked) {
            const home: NodeData | undefined = system.children?.find((child: NodeData) => child.dashPath === "home.json");
            // TODO: improve prompt UI
            if (home?.checked) {
              if (userHome !== HOME_DASHBOARD) {
                if(window.confirm("Home dashboard was imported. Would you like to override the current user Home with this?")){
                  setUserHomePage();
                }
              }
              if (orgHome !== HOME_DASHBOARD) {
                if(window.confirm("Home dashboard was imported. Would you like to override the current organization Home with this?")){
                  setOrgHomePage();
                }
              }
          }
        }
      }
    }
    });
  };

  const importDashboard =async (dashboardPath: string, folders: Folder[]) => {
    await fetchDashboard(dashboardPath).then(
      async resDash => {
        if (!resDash) {
          setStatus('error');
          setMessage(`${dashboardPath}: failed to find the source dashboard json to import.`);
          setShowAlert(true);
          return;
        }
        const {folderTitle} = resDash;
        let folderId: number | null = null;
        const folder = folderTitle ? folders?.find(f => f.title === folderTitle) : undefined;
        folderId = folder ? folder.id : null;
        if (folderTitle && folderTitle !== "General" && !folderId) {
          await postFolder({ title: folderTitle }).then(async resFolder => {
            const newFolder: Folder = resFolder;
            if (!!newFolder) {
              folders.push(newFolder);
              await postDash(resDash, newFolder.id, newFolder.title);
            } else {
              setStatus('error');
              setMessage(`${dashboardPath}: failed to create folder ${folderTitle}.`);
              setShowAlert(true);
              return;
            }
          });
        } else {
          await postDash(resDash, folderId, folderTitle);
        }
      }
    );
  }
  
  // import dashboard
  const postDash = async (dash: { title: string }, folderId: number | null,  folderTitle: string) => {
    const dashToImport = {
      dashboard: { ...dash, id: null },
      folderId: folderId,
      folderTitle: folderTitle,
      overwrite: true,
    };
    const response = await fetch(`${config.appSubUrl}/api/dashboards/db`, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify(dashToImport),
    });
    // set import status
    let success = false;
    if (response.status < 200) {
      setStatus('info');
    } else if (response.status < 300) {
      setStatus('success');
      success = true;
    } else if (response.status < 400) {
      setStatus('warning');
    } else {
      setStatus('error');
    }
    if (response.ok){
      const postedDash = await response.json();
      if (!postedDash) {
        setStatus('error')
        success = false;
      } 
    }
    // set message
    if (success) {
      setMessage(`"${dashToImport.dashboard.title}": successfully imported.`);
    } else {
      setMessage(`"${dashToImport.dashboard.title}": ${response.status}, ${response.statusText}`);
    }
    setShowAlert(true);
  };

  // Set home dashboard for current user
  const setUserHomePage = async () => {
    const response = await fetch(`${config.appSubUrl}/api/user/preferences`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"homeDashboardUID":HOME_DASHBOARD}),
    });
    if (!response.ok) { 
      setStatus('error');
      setMessage(`"Setting user Home dashboard": ${response.status}, ${response.statusText}`);
      setShowAlert(true);
    } else {
      setStatus('success');
      setMessage(`"Setting users Home dashboard": successfully set ${HOME_DASHBOARD} to be Home page.`);
      setShowAlert(true);
    }
  }

   // Set home dashboard for current user
   const setOrgHomePage = async () => {
    const response = await fetch(`${config.appSubUrl}/api/org/preferences`, {
      method: 'PATCH',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({"homeDashboardUID":HOME_DASHBOARD}),
    });
    if (!response.ok) { 
      setStatus('error');
      setMessage(`"Setting organization Home dashboard": ${response.status}, ${response.statusText}`);
      setShowAlert(true);
    } else {
      setStatus('success');
      setMessage(`"Setting organization Home dashboard": successfully set ${HOME_DASHBOARD} to be Home page.`);
      setShowAlert(true);
    }
  }

  const getUserHomePage = async () => {
    const response = await fetch(`${config.appSubUrl}/api/user/preferences`);
    if (!response.ok) { 
      return undefined;
    } else {
      const data = await response.json();
      if (!data || !data.homeDashboardUID || data.homeDashboardUID === "") {
        return undefined;
      } else {
        return data.homeDashboardUID;
      }
    }
  }

  const getOrgHomePage = async () => {
    const response = await fetch(`${config.appSubUrl}/api/org/preferences`);
    if (!response.ok) { 
      return undefined;
    } else {
      const data = await response.json();
      if (!data || !data.homeDashboardUID || data.homeDashboardUID === "") {
        return undefined;
      } else {
        return data.homeDashboardUID;
      }
    }
  }

  useEffect(() => {
    showAlert && window.setTimeout(() => setShowAlert(false), 2000);
  }, [showAlert]);
  
  return (
    <div>
      <VerticalGroup justify="center">
        {showAlert && (
          <Alert
            onRemove={(e: React.MouseEvent) => {
              setShowAlert(false);
            }}
            title={`${message}`}
            severity={status}
          ></Alert>
        )}
        <Button size="md" onClick={importDashboards} icon="download-alt">
          Import the selected dashboards below...
        </Button>
      </VerticalGroup>
    </div>
  );
};
