import React from 'react';
import { css } from '@emotion/css';
import { useStyles2 } from '@grafana/ui';

type Props = {
  [key: string]: any;
};

export const WithStyles = (WrappedComponent: React.FC<any>): React.FC<Props> => {
  const HOC: React.FC = (props: Props): JSX.Element => {
    const styles = useStyles2(() => {
      return {
        pagePadding: css`
          padding-top: 2rem;
          padding-left: 2rem;
          margin-bottom: 2rem;
        `,
      };
    });
    
    return <WrappedComponent {...props} styles={styles} />;
  };
  return HOC;
};
